import React from "react";
import { SparklesCore } from "./sparklesCore";

export function SparklesHero() {
  return (
    <div className="h-[100vh] relative w-full bg-black flex flex-col items-center justify-center overflow-hidden rounded-md px-4 text-center">
      {/* Particle Effect */}
      <div className="w-full absolute inset-0 h-screen">
        <SparklesCore
          id="tsparticlesfullpage"
          background="transparent"
          minSize={0.6}
          maxSize={1.4}
          particleDensity={100}
          className="w-full h-full"
          particleColor="#FFFFFF"
        />
      </div>

      {/* Hero Text */}
      <h1 className="text-white font-bold relative z-20 leading-tight text-[clamp(2rem,5vw,4.5rem)]">
        Worried About <br /> CRM, ERP Implementation Costs?
      </h1>

      <p className="max-w-4xl text-white mt-6 text-[clamp(1rem,2.5vw,1.5rem)] md:px-4">
        We are a team of experienced, passionate developers working in the ERP/CRM industry for 12 years. Let us onboard your business processes onto Salesforce/Odoo.
      </p>

      <p className="max-w-4xl text-white mt-4 text-[clamp(1rem,2.5vw,1.5rem)]">
        And for the first two weeks, it's on us!
      </p>

      {/* Call to Action */}
      <a className="btn btn-primary mt-8 text-white z-10 text-[clamp(1rem,2.5vw,1.2rem)]" href="/trial">
        Book Free 2 Weeks Trial
      </a>
    </div>
  );
}
