import React from 'react'
import PropTypes from 'prop-types'

function CRMDetails(props) {
  const {data, selectedIndex} = props;
  const selectedItem = data[selectedIndex];
  const {name, description, bp} = selectedItem;  
  return ( 
    <div className="card bg-base-100 shadow-xl mt-4 ml-8 h-[37rem]">
        <div className="card-body">
            <h2 className='card-title my-4'> {name}</h2>        
            {description}
            {bp.map((point) => <li>{point}</li>)}
        </div>   
    </div> 
  )
}

CRMDetails.propTypes = {}

export default CRMDetails
