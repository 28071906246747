import React from 'react'
import PropTypes from 'prop-types'
import CRMSetvices from '../Components/CRMServices';
import { salesforceImplementationHeadings, salesforceListDetails } from '../Content/Content';

function SalesforcePage(props) {
  return (<div>
    <CRMSetvices data= {salesforceListDetails} headings={salesforceImplementationHeadings}></CRMSetvices> 
  </div>)
}

SalesforcePage.propTypes = {}

export default SalesforcePage    
