import React from 'react'
import PropTypes from 'prop-types'

function ListItem(props) {
    const {item, index, isSelected} = props;
    console.log("isSelected",index, isSelected)
    return <li key={index} onClick={() => {
        props.onClick(index)}} className={`card bg-base-100 shadow-xl my-4 mr-4 
          hover:bg-[#191d26] hover:text-white 
          ${props.selectedIndex === index ? "bg-[#191d26] text-white" : ""} 
          cursor-pointer`}
>      
            <div className="card-body px-4">
            <h3 className='card-title'> {item.name ? item.name: ""}</h3>
            <p>{item.subHeading}</p>
            </div>
        </li>
}

function CRMListItems(props) {
  const {data, selectedIndex} = props;
  const selectedItem = data[selectedIndex];
  console.log("selected", selectedIndex)
  console.log("data", data)
  return (
    <div className={"flex flex-col h-[40rem] overflow-auto"}>
    {data.map(
        (item,index) => <ListItem index={index} key={index} item={item} onClick={props.onClick} isSelected = {index == selectedIndex} ></ListItem>
    )}
    </div>    
  )
}

CRMListItems.propTypes = {}

export default CRMListItems
