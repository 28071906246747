export const eCommerceCRMHeadings = [
    {
        name: "Data Collection",
        subHeading: "Our CRM solutions have advanced data collection functionalities like:",
        description: "Data filtration is a crucial aspect of your operations. Access to high volumes of data requires you to be specific about what’s required for your business. Our custom CRM services allow you to filter data that actively matches your customers’ interests. Foster better revenue through informed decision-making with our data filtration services. Now simplify operations with: ",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]

    },
    {
        name: "Data Filtration",
        subHeading: "Our CRM solutions have advanced data collection functionalities like:",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]
    
    },
    {
        name: "Lead Generation",
        subHeading: "Our CRM solutions have advanced data collection functionalities like:",
        description: "Data filtration is a crucial aspect of your operations. Access to high volumes of data requires you to be specific about what’s required for your business. Our custom CRM services allow you to filter data that actively matches your customers’ interests. Foster better revenue through informed decision-making with our data filtration services. Now simplify operations with: ",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]

    },
    {
        name: "Lead Qualification",
        subHeading: "Our CRM solutions have advanced data collection functionalities like:",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
]


export const odooImplementationHeadings = {
        heading: "Odoo ERP & CRM Software Development Solutions",
        subHeading: "All your business on one platform, simple, efficient yet affordable!"
}

export const salesforceImplementationHeadings = {
  heading: "Salesforce Software Development Solutions",
  subHeading: "World’s leading CRM platform that empowers businesses to streamline sales, service, marketing, and more with cutting-edge cloud-based solutions."
}

export const salesforceListDetails = [
  {
    name: "Sales Cloud",
    subHeading: "Boost Sales Productivity",
    description: "Sales Cloud is designed to help your sales team close deals faster by managing leads, opportunities, and accounts in a single platform.",
    bp: [
      "Track customer interactions and sales activities in real-time.",
      "Automate repetitive tasks and workflows to increase efficiency.",
      "Generate accurate forecasts and gain actionable insights with AI-driven analytics."
    ]
  },
  {
    name: "Service Cloud",
    subHeading: "Deliver Exceptional Customer Service",
    description: "Service Cloud empowers your customer support team to provide personalized, efficient service across multiple channels.",
    bp: [
      "Handle cases from email, chat, phone, and social media in one interface.",
      "Enable customers to find solutions independently with self-service portals.",
      "Use AI to recommend solutions and predict customer satisfaction."
    ]
  },
  {
    name: "Marketing Cloud",
    subHeading: "Transform Customer Engagement",
    description: "Marketing Cloud allows businesses to create personalized marketing campaigns and automate customer journeys.",
    bp: [
      "Design tailored email campaigns with dynamic content.",
      "Automate marketing workflows to save time and resources.",
      "Leverage analytics to measure campaign success and refine strategies."
    ]
  },
  {
    name: "Commerce Cloud",
    subHeading: "Build Seamless Shopping Experiences",
    description: "Commerce Cloud enables businesses to provide unified and personalized e-commerce experiences for their customers.",
    bp: [
      "Integrate online and offline shopping experiences.",
      "Optimize conversion rates with AI-driven recommendations.",
      "Manage inventory, orders, and promotions effectively."
    ]
  },
  {
    name: "Einstein AI",
    subHeading: "Smart AI Solutions Across the Platform",
    description: "Einstein AI integrates intelligence into Salesforce products, offering predictive insights and automation.",
    bp: [
      "Get lead scoring and opportunity insights with Einstein Lead Scoring.",
      "Automate repetitive tasks with AI-powered tools.",
      "Predict outcomes and optimize customer interactions."
    ]
  },
  {
    name: "Salesforce Platform",
    subHeading: "Build Apps with Ease",
    description: "The Salesforce Platform empowers businesses to create custom applications to meet their specific needs.",
    bp: [
      "Build apps using low-code or pro-code tools.",
      "Customize workflows and dashboards for better productivity.",
      "Integrate data seamlessly from third-party sources."
    ]
  },
  {
    name: "AppExchange",
    subHeading: "Access the Salesforce Marketplace",
    description: "AppExchange offers a marketplace of apps, integrations, and consulting services to enhance Salesforce functionality.",
    bp: [
      "Choose from thousands of pre-built apps and solutions.",
      "Integrate with third-party tools effortlessly.",
      "Access certified consulting partners for expert guidance."
    ]
  },
  {
    name: "Slack Integration",
    subHeading: "Collaborate and Streamline Workflows",
    description: "Slack integrates with Salesforce to enhance team collaboration and automate workflows.",
    bp: [
      "Enable instant communication within teams and across departments.",
      "Automate task updates and notifications directly in Slack.",
      "Streamline customer and project management workflows."
    ]
  },
  {
    name: "Tableau Analytics",
    subHeading: "Visualize and Analyze Data",
    description: "Tableau helps businesses unlock insights with interactive dashboards and data visualizations.",
    bp: [
      "Visualize sales, service, and marketing data with ease.",
      "Share insights and reports across teams in real-time.",
      "Connect to a variety of data sources for comprehensive analysis."
    ]
  }
];


export const odooListDetails = [
    {    
        name: "Odoo App/Module Development",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Odoo Themes Development",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Odoo Website Development",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Odoo POS Development",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Odoo Hosting and Server Development",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "CRM, Marketing and Helpdesk",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Supply Chain Solutions",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Reatil Omni Channel Solution",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Production and MRP",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "HRMS",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Accounting and Finance",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "eCommerce and Marketplace",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
]

export const products = [
    {
      title: "SalesForce Sales Cloud",
      link: "https://www.salesforce.com/in/sales/",
      thumbnail:
        "../../../images/hero/SalesCloud.png",
    },
    {
      title: "Salesforce CPQ",
      link: "https://www.salesforce.com/sales/cpq/",
      thumbnail:
      "../../../images/hero/SalesCPQ.png",
    },
    {
      title: "Odoo Accounts",
      link: "https://www.odoo.com/app/accounting",
      thumbnail:
        "../../../images/hero/OdooAccounts.png",
    },
    {
      title: "Odoo HRMS",
      link: "https://www.odoo.com/app/employees",
      thumbnail: "../../../images/hero/OdooHRMS.png",
    },  
    {
      title: "Odoo Website",
      link: "https://www.odoo.com/app/website",
      thumbnail: "../../../images/hero/OdooWebsite.png",
    }, 
    {
      title: "SalesForce Einstein AI",
      link: "https://www.salesforce.com/in/artificial-intelligence/",    
      thumbnail:"../../../images/hero/SalesForceEinsteinAI.png",      
    },
    {
      title: "Odoo POS",
      link: "https://www.odoo.com/app/point-of-sale-shop",
      thumbnail:"../../../images/hero/OdooPOS.png",
    }, 
    {
      title: "SalesForce Financial Service Cloud",
      link: "https://www.salesforce.com/financial-services/cloud/",    
      thumbnail:"../../../images/hero/SFFinancialServiceCloud.png",
    },
    {
      title: "Odoo Marketing Automation",
      link: "https://www.odoo.com/app/marketing-automation",
      thumbnail:"../../../images/hero/OdooMarketingAutomation.png",
    },
    {
      title: "SalesForce Experience Cloud",
      link: "https://www.salesforce.com/in/products/experience-cloud/overview/",    
      thumbnail:"../../../images/hero/SFExperienceCloud.png",
    },
    {
      title: "Odoo Website",
      link: "https://www.odoo.com/app/website",
      thumbnail: "../../../images/hero/OdooWebsite.png",
    }, 
    {
      title: "SalesForce Marketing Cloud",
      link: "https://www.salesforce.com/in/marketing/",
      thumbnail: "../../../images/hero/SFMarketingCloud.png",
    },
    {
      title: "SalesForce Analytics",
      link: "https://www.salesforce.com/in/analytics/",
      thumbnail: "../../../images/hero/SFTableau.png",
    },
    {
      title: "Odoo Manufacturing CRM",
      link: "https://www.odoo.com/app/manufacturing",
      thumbnail: "../../../images/hero/OdooManufacturingCrm.png",
    },
  ]