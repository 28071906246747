import React from 'react'
import PropTypes from 'prop-types'

function Footer(props) {
  return (
    <div><footer className="footer bg-neutral text-neutral-content p-10">
    <nav>
      <h6 className="footer-title">Services</h6>
      <a className="link link-hover">Odoo</a>
      <a className="link link-hover">SalesForce</a>
      {/* <a className="link link-hover">Marketing</a> */}
      {/* <a className="link link-hover">Advertisement</a> */}
    </nav>
    <nav>
      <h6 className="footer-title">Company</h6>
      <a className="link link-hover">About us</a>
      <a className="link link-hover">Contact</a>
      {/* <a className="link link-hover">Jobs</a> */}
      {/* <a className="link link-hover">Press kit</a> */}
    </nav>
    <nav>
      <h6 className="footer-title">Legal</h6>
      <a href="./terms" className="link link-hover">Terms of use</a>
      <a href="./privacy-policy" className="link link-hover">Privacy policy</a>      
      <a className="link link-hover">Cookie policy</a>
    </nav>
  </footer></div>
  )
}

Footer.propTypes = {}

export default Footer
