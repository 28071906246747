// import Head from 'next/head';
//import {Head} from "next";

import { Helmet } from "react-helmet";


function TermsOfUse(props) { 
    return (
        <>
            {/* <Head>
                <title>Terms of Use | Tabvik</title>
                <meta name="description" content="Terms of Use for Tabvik's website and services." />
            </Head> */}
            <main style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
                <Helmet>
                    <title>Tabvik - TermsOfUse</title>
                </Helmet>
                <h1><strong>Terms of Use</strong></h1>
                <p className="my-4"><strong>Effective Date:</strong> 17/11/2024</p>

                <section className="my-4">
                    <h2>1. Definitions</h2>
                    <p>
                        <strong>"We," "Us," or "Our"</strong> refers to Tabvik Technologies.<br />
                        <strong>"You" or "User"</strong> refers to any individual or entity accessing the Website.<br />
                        <strong>"Services"</strong> refers to the Odoo Development, Salesforce Development and other services provided by Tabvik.
                    </p>
                </section>

                <section className="my-4">
                    <h2>2. Acceptance of Terms</h2>
                    <p>
                        By using this Website, you affirm that you are at least 18 years old or accessing the Website under the supervision of a parent or guardian. Your use of the Website constitutes your acceptance of these Terms.
                    </p>
                </section>

                <section className="my-4">
                    <h2>3. Use of the Website</h2>
                    <p>
                        You agree to use the Website only for lawful purposes. You are prohibited from:
                        <ul>
                            <li>Engaging in any activity that disrupts or interferes with the Website's functionality.</li>
                            <li>Attempting to gain unauthorized access to any part of the Website.</li>
                            <li>Using the Website for fraudulent purposes.</li>
                        </ul>
                    </p>
                </section>

                <section className="my-4">
                    <h2>4. Intellectual Property</h2>
                    <p>
                        All content, trademarks, logos, and other intellectual property displayed on the Website are owned by or licensed to Tabvik. You may not reproduce, distribute, or otherwise use any content from the Website without prior written permission.
                    </p>
                    <p>
                        The use of these logos, trademarks, or brand names on our website or in our materials is strictly for reference purposes and does not imply any partnership, sponsorship, or endorsement by Odoo S.A. or Salesforce.com, Inc.
                    </p>
                    <p>
                        Tabvik does not claim ownership of any third-party trademarks or intellectual property. Any services provided by Tabvik related to Odoo or Salesforce are based on our independent expertise and are not directly provided, supported, or managed by Odoo S.A. or Salesforce.com, Inc.
                    </p>
                </section>

                <section className="my-4">
                    <h2>5. Disclaimer of Warranties</h2>
                    <p>
                        The Website and its content are provided "as is" and "as available." We make no representations or warranties of any kind, express or implied, regarding the Website's content, functionality, or availability.
                    </p>
                </section>

                <section className="my-4">
                    <h2>6. Limitation of Liability</h2>
                    <p>
                        To the fullest extent permitted by law, Tabvik will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the Website.
                    </p>
                </section>

                <section className="my-4">
                    <h2>7. Links to Third-Party Websites</h2>
                    <p>
                        The Website may contain links to third-party websites. We do not endorse or take responsibility for the content or practices of these third-party websites.
                    </p>
                </section>

                <section className="my-4">
                    <h2>8. Privacy Policy</h2>
                    <p>
                        Your use of the Website is also governed by our <a href="/privacy-policy">Privacy Policy</a>, which is incorporated into these Terms by reference.
                    </p>
                </section>

                <section className="my-4">
                    <h2>9. Changes to Terms</h2>
                    <p>
                        We reserve the right to modify these Terms of Use at any time. Changes will be effective upon posting to the Website. Your continued use of the Website constitutes your acceptance of the revised Terms.
                    </p>
                </section>

                <section className="my-4">
                    <h2>10. Governing Law</h2>
                    <p>
                        These Terms of Use are governed by and construed in accordance with the laws of India. Any disputes will be subject to the exclusive jurisdiction of the courts in New Delhi, India.
                    </p>
                </section>

                <section className="my-4">
                    <h2>11. Contact Us</h2>
                    <p>
                        For any questions or concerns regarding these Terms, please contact us at:
                        <ul>
                            <li><strong>Email:</strong> contact@tabvik.com</li>
                            <li><strong>Phone:</strong> +91 7982542446</li>
                        </ul>
                    </p>
                </section>
            </main>
        </>
    );
};

export default TermsOfUse;
