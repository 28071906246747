import React from "react";

const PrivacyPolicy = () => {
  return (
    <main style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1><strong>Privacy Policy</strong></h1>
      <section className="my-4">
      <p><strong>Effective Date:</strong> 17/11/2024</p>
      <p>
        Tabvik ("we," "our," or "us") is committed to protecting your privacy.
        This Privacy Policy describes how we collect, use, and disclose
        information about users of our website (the "Site") and services (the
        "Services"). By using our Site or Services, you agree to the practices
        described in this Privacy Policy.
      </p>
      </section>

      <section className="my-4">
      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <h3 className="ml-4">a. Personal Information</h3>
      <ul className="ml-4 pl-4">
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Company name</li>
        <li>Any other information you provide when filling out forms or contacting us.</li>
      </ul>
      <h3 className="ml-4">b. Usage Information</h3>
      <ul className="ml-4 pl-4">
        <li>IP address</li>
        <li>Browser type</li>
        <li>Operating system</li>
        <li>Pages visited on our Site</li>
        <li>Date and time of visits</li>
      </ul>
      <h3 className="ml-4">c. Cookies and Tracking Technologies</h3>
      <p className="ml-4 pl-4">
        We use cookies and similar technologies to enhance user experience,
        analyze site traffic, and for advertising purposes. You can control
        cookies through your browser settings.
      </p>
      </section>

      <section className="my-4">
      <h2>2. How We Use Your Information</h2>
      <p className="ml-4">We use the collected information for the following purposes:</p>
      <ul className="ml-4 pl-4">
        <li>To provide, operate, and maintain our Services.</li>
        <li>To respond to inquiries, provide customer support, and process requests.</li>
        <li>To improve our Site, Services, and user experience.</li>
        <li>To send marketing communications, with your consent where required.</li>
        <li>To comply with legal obligations.</li>
      </ul>

      <h2>3. How We Share Your Information</h2>
      <p className="ml-4">We do not sell your personal information. However, we may share your information with:</p>
      <ul className="ml-4">
        <li>
          <strong>Service Providers:</strong> Third-party vendors who assist us
          with operations, such as hosting, analytics, and marketing.
        </li>
        <li>
          <strong>Legal Authorities:</strong> To comply with applicable laws,
          regulations, or legal processes.
        </li>
        <li>
          <strong>Business Transfers:</strong> In connection with a merger, sale,
          or transfer of assets.
        </li>
      </ul>
      </section>

      <section className="my-4">
      <h2>4. Third-Party Services</h2>
      <p className="ml-4">
        Our Site and Services may include links to third-party websites, tools,
        or platforms (e.g., Odoo, Salesforce). We are not responsible for the
        privacy practices of these third parties. We encourage you to review
        their privacy policies.
      </p>
      </section>

      <section className="my-4">
      <h2>5. Data Retention</h2>
      <p className="ml-4">
        We retain your personal information only as long as necessary to fulfill
        the purposes outlined in this Privacy Policy, comply with legal
        obligations, resolve disputes, and enforce our agreements.
      </p>
      </section>

      <section className="my-4">
      <h2>6. Your Rights</h2>
      <p className="ml-4">You have the following rights regarding your personal information:</p>
      <ul className="ml-4 pl-4">
        <li><strong>Access and Correction:</strong> Request access to or correction of your personal data.</li>
        <li><strong>Data Portability:</strong> Request a copy of your data in a structured format.</li>
        <li><strong>Deletion:</strong> Request deletion of your personal data, subject to legal obligations.</li>
        <li><strong>Opt-Out:</strong> Opt out of marketing communications.</li>
      </ul>
      <p className="ml-4">
        To exercise your rights, contact us at <strong>contact@tabvik.com</strong>.
      </p>
      </section>

      <section className="my-4">
      <h2>7. Security</h2>
      <p className="ml-4">
        We take reasonable administrative, technical, and physical measures to
        protect your information from unauthorized access, use, or disclosure.
        However, no security system is foolproof.
      </p>
      </section>

      <section className="my-4">
      <h2>8. Children's Privacy</h2>
      <p className="ml-4">
        Our Site and Services are not directed at children under the age of 13.
        We do not knowingly collect personal information from children. If we
        become aware that we have collected such information, we will take steps
        to delete it.
      </p>
      </section>

      <section className="my-4">
      <h2>9. Updates to This Privacy Policy</h2>
      <p className="ml-4">
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with the updated effective date. Your continued use
        of our Site or Services constitutes your acceptance of the revised
        policy.
      </p>
      </section>

     <section className="my-4">
      <h2>10. Contact Us</h2>
      <p className="ml-4">
        If you have any questions or concerns about this Privacy Policy or our
        practices, please contact us at:
      </p>
      <address className="pl-4">
        <strong>Tabvik Technologies Pvt Ltd</strong>
        <br />                
        Email: contact@tabvik.com
        <br />
        Phone: +91 7982542446
      </address>
      </section>
    </main>
  );
};

export default PrivacyPolicy;
