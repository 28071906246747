import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';



function NavBar() {
  return (
    <div className="navbar bg-base-100 px-4 lg:px-32 relative z-30 w-full">
      {/* Mobile Dropdown Menu */}
      <div className="navbar-center lg:hidden">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-30 mt-3 w-52 p-2 shadow">
            
            <li>
              <details>
                <summary>Services</summary>
                <ul className="p-2">
                  <li><Link to="/odoo">Odoo</Link></li>
                  <li><Link to="/salesforce">Salesforce</Link></li>
                </ul>
              </details>
            </li>
            <li>
              <details>
                <summary>ERP</summary>
                <ul className="p-2">
                  <li><Link to="/transportErp">Transport ERP</Link></li>
                  <li><Link to="/schoolErp">School ERP</Link></li>
                </ul>
              </details>
            </li>
            <li>
              <details>
                <summary>App</summary>
                <ul className="p-2">
                  <li><Link to="/odoo">Odoo CRM</Link></li>
                </ul>
              </details>
            </li>
            
            <li><Link to="/contact">Contact Us</Link></li> 
          </ul>
        </div>
      </div>
      {/* Left Side: TABVIK (Logo) */}
      <div className="navbar-start ">
        <Link to="/" >
        <img src="/Tabvik logo.png"   className="absolute h-16 top-1 w-16  rounded-full object-cover border-2 border-gray-300 shadow-md"/>
        </Link>
      </div>

      {/* Center: Navigation Menu (Visible Only on Large Screens) */}
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li>
            <details>
              <summary className=' text-center  pt-4 '>Services</summary>
              <ul className="p-2">
                <li><Link to="/odoo">Odoo</Link></li>
                <li><Link to="/salesforce">Salesforce</Link></li>
              </ul>
            </details>
          </li>
          <li>
            <details className="mx-7">
              <summary className='  text-center pt-4'>ERP</summary>
              <ul className="p-4">
                <li><Link to="/transportErp">Transport ERP</Link></li>
                <li><Link to="/schoolErp">School ERP</Link></li>
              </ul>
            </details>
          </li>
          <li>
            <details className="mx-7">
              <summary className='  text-center pt-4'>App</summary>
              <ul className="p-4">
                <li><Link to="/odooCrm">Odoo CRM</Link></li>
              </ul>
            </details>
          </li>
          <li><Link to="/contact" className="mt-2">Contact Us</Link></li>
        </ul>
      </div>

      {/* Right Side: Trial Button (Aligned to Right on Desktop) */}
      <div className="navbar-end flex justify-end ">
        <Link to="/trial" className="btn btn-primary w-full sm:w-auto md:btn-md btn-sm max-w-xs ">Book Free 2 Weeks Trial</Link>
      </div>
    </div>
  );
}

export default NavBar;
