import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


function NavBar(props) {
  return (
    <div className="navbar bg-base-100 px-32 relative z-30">
      <div className="navbar-start container">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-30 mt-3 w-52 p-2 shadow">
            {/* <li><a>Industries</a></li> */}
            <li>
              <a>Services</a>
              <ul className="p-2">
                <li><Link to="/odoo">Odoo</Link></li>
                <li><a>Salesforce</a></li>
              </ul>
            </li>
            <li><a>Contact Us</a></li>
          </ul>
        </div>
        {/* <a className="btn btn-ghost text-xl">TABVIK</a> */}
        <Link to="/" className="btn btn-ghost text-xl">TABVIK</Link>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          {/* <li><a>Industries</a></li> */}
          <li>
            <details className="z-30">
              <summary>Services</summary>
              <ul className="p-2">
                <li><Link to="/odoo">Odoo</Link></li>
                <li><Link to="/salesforce">Salesforce</Link></li>
              </ul>
            </details>
          </li>
          <li><a>Contact Us</a></li>
        </ul>
      </div>
      <div className="navbar-end">
        <a className="btn btn-primary" href='\trial'>Book Free 2 Weeks Trial</a>
      </div>
    </div>
  );
}

NavBar.propTypes = {};

export default NavBar;
