import React from 'react'
import PropTypes from 'prop-types'
import CRMSetvices from '../Components/CRMServices';
import { PageHeadings, salesforceImplementationHeadings, salesforceListDetails } from '../Content/Content';
import { Helmet } from 'react-helmet';
import ContactForm from '../Components/ContactForm';


function SalesforcePage(props) {
  return (<div>
    <Helmet>
      <title>Tabvik - Salesforce</title>
    </Helmet>
    <CRMSetvices data= {salesforceListDetails} headings={salesforceImplementationHeadings}></CRMSetvices> 
    <ContactForm headings={PageHeadings.salesforce}/>
  </div>)
}

SalesforcePage.propTypes = {}

export default SalesforcePage    
