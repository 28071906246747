export const eCommerceCRMHeadings = [
    {
        name: "Data Collection",
        subHeading: "Our CRM solutions have advanced data collection functionalities like:",
        description: "Data filtration is a crucial aspect of your operations. Access to high volumes of data requires you to be specific about what’s required for your business. Our custom CRM services allow you to filter data that actively matches your customers’ interests. Foster better revenue through informed decision-making with our data filtration services. Now simplify operations with: ",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]

    },
    {
        name: "Data Filtration",
        subHeading: "Our CRM solutions have advanced data collection functionalities like:",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]
    
    },
    {
        name: "Lead Generation",
        subHeading: "Our CRM solutions have advanced data collection functionalities like:",
        description: "Data filtration is a crucial aspect of your operations. Access to high volumes of data requires you to be specific about what’s required for your business. Our custom CRM services allow you to filter data that actively matches your customers’ interests. Foster better revenue through informed decision-making with our data filtration services. Now simplify operations with: ",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]

    },
    {
        name: "Lead Qualification",
        subHeading: "Our CRM solutions have advanced data collection functionalities like:",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
]

 
export const odooImplementationHeadings = {
        heading: "Odoo ERP & CRM Software Development Solutions",
        subHeading: "All your business on one platform, simple, efficient yet affordable!"
}

export const salesforceImplementationHeadings = {
  heading: "Salesforce Software Development Solutions",
  subHeading: "World’s leading CRM platform that empowers businesses to streamline sales, service, marketing, and more with cutting-edge cloud-based solutions."
}

export const salesforceListDetails = [
  {
    name: "Sales Cloud",
    subHeading: "Boost Sales Productivity",
    description: "Sales Cloud is designed to help your sales team close deals faster by managing leads, opportunities, and accounts in a single platform.",
    bp: [
      "Track customer interactions and sales activities in real-time.",
      "Automate repetitive tasks and workflows to increase efficiency.",
      "Generate accurate forecasts and gain actionable insights with AI-driven analytics."
    ]
  },
  {
    name: "Service Cloud",
    subHeading: "Deliver Exceptional Customer Service",
    description: "Service Cloud empowers your customer support team to provide personalized, efficient service across multiple channels.",
    bp: [
      "Handle cases from email, chat, phone, and social media in one interface.",
      "Enable customers to find solutions independently with self-service portals.",
      "Use AI to recommend solutions and predict customer satisfaction."
    ]
  },
  {
    name: "Marketing Cloud",
    subHeading: "Transform Customer Engagement",
    description: "Marketing Cloud allows businesses to create personalized marketing campaigns and automate customer journeys.",
    bp: [
      "Design tailored email campaigns with dynamic content.",
      "Automate marketing workflows to save time and resources.",
      "Leverage analytics to measure campaign success and refine strategies."
    ]
  },
  {
    name: "Commerce Cloud",
    subHeading: "Build Seamless Shopping Experiences",
    description: "Commerce Cloud enables businesses to provide unified and personalized e-commerce experiences for their customers.",
    bp: [
      "Integrate online and offline shopping experiences.",
      "Optimize conversion rates with AI-driven recommendations.",
      "Manage inventory, orders, and promotions effectively."
    ]
  },
  {
    name: "Einstein AI",
    subHeading: "Smart AI Solutions Across the Platform",
    description: "Einstein AI integrates intelligence into Salesforce products, offering predictive insights and automation.",
    bp: [
      "Get lead scoring and opportunity insights with Einstein Lead Scoring.",
      "Automate repetitive tasks with AI-powered tools.",
      "Predict outcomes and optimize customer interactions."
    ]
  },
  {
    name: "Salesforce Platform",
    subHeading: "Build Apps with Ease",
    description: "The Salesforce Platform empowers businesses to create custom applications to meet their specific needs.",
    bp: [
      "Build apps using low-code or pro-code tools.",
      "Customize workflows and dashboards for better productivity.",
      "Integrate data seamlessly from third-party sources."
    ]
  },
  {
    name: "AppExchange",
    subHeading: "Access the Salesforce Marketplace",
    description: "AppExchange offers a marketplace of apps, integrations, and consulting services to enhance Salesforce functionality.",
    bp: [
      "Choose from thousands of pre-built apps and solutions.",
      "Integrate with third-party tools effortlessly.",
      "Access certified consulting partners for expert guidance."
    ]
  },
  {
    name: "Slack Integration",
    subHeading: "Collaborate and Streamline Workflows",
    description: "Slack integrates with Salesforce to enhance team collaboration and automate workflows.",
    bp: [
      "Enable instant communication within teams and across departments.",
      "Automate task updates and notifications directly in Slack.",
      "Streamline customer and project management workflows."
    ]
  },
  {
    name: "Tableau Analytics",
    subHeading: "Visualize and Analyze Data",
    description: "Tableau helps businesses unlock insights with interactive dashboards and data visualizations.",
    bp: [
      "Visualize sales, service, and marketing data with ease.",
      "Share insights and reports across teams in real-time.",
      "Connect to a variety of data sources for comprehensive analysis."
    ]
  }
];


export const odooListDetails = [
    {    
        name: "Odoo App/Module Development",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Odoo Themes Development",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Odoo Website Development",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Odoo POS Development",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Odoo Hosting and Server Development",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "CRM, Marketing and Helpdesk",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Supply Chain Solutions",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Reatil Omni Channel Solution",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Production and MRP",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "HRMS",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "Accounting and Finance",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
    {    
        name: "eCommerce and Marketplace",
        subHeading: "Bring your business on to odoo's app and modules",
        description: "Let’s target and improve your lead generation process with custom solutions that add to your operations. Our custom CRM solutions are designed to identify potential customers for your business and guide them further into the sales pipeline. Target pre-qualified customers that add to your sales and revenue. Now tap into:",
        bp: ["Access to CRM solutions designed to filter data at rigorous levels.", "Custom products that help you scrap, filter, and store data sets based on different criteria and customer profiling.","Result-oriented data that brings you the desired results." ]    
    },
]
export const schoolERPHeadings = {
  heading: "School ERP Software Solutions",
  subHeading: "A next-generation platform designed to streamline administrative, academic, and communication processes for schools, ensuring efficiency, transparency, and student success."
}

export const schoolERPListDetails = [
  {
    name: "Student Management",
    subHeading: "Efficient Student Data Handling",
    description: "A centralized system to manage student profiles, academic records, attendance, and progress reports.",
    bp: [
      "Maintain complete student records, including personal details, academic history, and disciplinary notes.",
      "Automate attendance tracking using biometric or RFID-based systems.",
      "Generate real-time performance reports and analytics to help teachers and parents monitor progress.",
      "Seamlessly manage student admissions, transfers, and promotions."
    ]
  },
  {
    name: "Teacher Management",
    subHeading: "Empower Educators with Smart Tools",
    description: "A dedicated module to assist teachers in managing classes, assignments, and interactions with students and parents.",
    bp: [
      "Easily schedule and manage class timetables and subject allocations.",
      "Assign, track, and evaluate homework, assignments, and project work.",
      "Enable seamless communication with students and parents through messaging and notifications.",
      "Monitor teacher performance with feedback and assessment tools."
    ]
  },
  {
    name: "Fee Management",
    subHeading: "Automate Fee Collection and Tracking",
    description: "A robust system to streamline fee collection, automate invoicing, and ensure accurate financial tracking.",
    bp: [
      "Generate and send digital fee invoices and payment reminders.",
      "Allow parents to make payments via multiple payment gateways, including UPI, credit/debit cards, and net banking.",
      "Track pending and completed payments with real-time updates.",
      "Automate fine calculations for late payments and generate financial reports."
    ]
  },
  {
    name: "Timetable Management",
    subHeading: "Seamless Class Scheduling",
    description: "Automate and optimize timetable scheduling to prevent conflicts and ensure smooth academic operations.",
    bp: [
      "Generate class timetables based on teacher availability and subject distribution.",
      "Automatically adjust schedules for substitute teachers during absences.",
      "Prevent scheduling conflicts and optimize classroom usage.",
      "Provide instant updates on schedule changes via mobile notifications."
    ]
  },
  {
    name: "Library Management",
    subHeading: "Digitized Library System",
    description: "A modern, automated library system that simplifies book issuance, tracking, and returns.",
    bp: [
      "Maintain a digital catalog of books, journals, and e-resources.",
      "Enable students and staff to search, reserve, and borrow books online.",
      "Automatically calculate overdue fines and notify users of return deadlines.",
      "Track book availability and generate detailed reports on library usage."
    ]
  },
  {
    name: "Examination Management",
    subHeading: "Organized and Automated Exams",
    description: "A complete solution for managing online and offline examinations, grading, and report generation.",
    bp: [
      "Create and schedule exams, quizzes, and assessments with ease.",
      "Automate grading for multiple-choice and objective exams.",
      "Generate and distribute student report cards and performance analytics.",
      "Maintain exam security with controlled access and authentication measures."
    ]
  },
  {
    name: "Parent Portal",
    subHeading: "Enhance Parent-School Communication",
    description: "A dedicated portal to keep parents informed about their child’s academic progress, attendance, and school activities.",
    bp: [
      "Provide parents with real-time access to attendance, grades, and fee payment status.",
      "Send automated notifications and alerts regarding school events, exams, and holidays.",
      "Facilitate direct communication with teachers and administrators.",
      "Allow parents to request appointments with teachers and track school bus routes."
    ]
  },
  {
    name: "Transport Management",
    subHeading: "Safe and Efficient School Transport",
    description: "A GPS-enabled transport management system for monitoring school buses and ensuring student safety.",
    bp: [
      "Track school bus routes and real-time GPS locations of vehicles.",
      "Notify parents about bus arrivals, delays, and student boarding status.",
      "Automate attendance marking for students boarding the school bus.",
      "Maintain detailed records of drivers, bus schedules, and maintenance logs."
    ]
  },
  {
    name: "Hostel Management",
    subHeading: "Streamline School Boarding Facilities",
    description: "A module designed to efficiently manage school hostels, accommodations, and student welfare.",
    bp: [
      "Maintain detailed records of hostel rooms, allocations, and student preferences.",
      "Track student attendance and visitor logs within hostel premises.",
      "Manage meal plans, laundry services, and housekeeping schedules.",
      "Automate hostel fee collection and maintenance tracking."
    ]
  },
  {
    name: "Attendance Management",
    subHeading: "Automated Attendance Tracking",
    description: "A smart attendance system that ensures accuracy and eliminates manual record-keeping errors.",
    bp: [
      "Mark attendance using RFID, biometric, or facial recognition technology.",
      "Provide real-time attendance updates to parents via SMS or app notifications.",
      "Generate detailed reports on student and staff attendance patterns.",
      "Integrate attendance data with payroll and academic performance reports."
    ]
  },
  {
    name: "HR & Payroll Management",
    subHeading: "Streamlined Staff Payroll and HR Processes",
    description: "A complete HR management solution for school staff, covering recruitment, payroll, and performance evaluation.",
    bp: [
      "Automate payroll processing with tax and deduction calculations.",
      "Track staff attendance, leaves, and work schedules.",
      "Manage employee benefits, appraisals, and performance tracking.",
      "Generate payslips and salary reports with ease."
    ]
  },
  {
    name: "Inventory & Asset Management",
    subHeading: "Manage School Resources Effectively",
    description: "A system to track and manage school assets, including stationery, furniture, and IT equipment.",
    bp: [
      "Monitor inventory levels and automate stock replenishment.",
      "Track asset allocation and maintenance schedules.",
      "Prevent losses and theft with barcode-based tracking systems.",
      "Generate reports on asset usage and depreciation."
    ]
  },
  {
    name: "Communication & Messaging System",
    subHeading: "Improve Internal and External Communication",
    description: "A unified communication system to ensure seamless interaction between teachers, students, parents, and administrators.",
    bp: [
      "Send automated SMS, emails, and app notifications for important announcements.",
      "Enable real-time chat and discussion forums for student-teacher collaboration.",
      "Schedule and manage online parent-teacher meetings.",
      "Integrate with video conferencing tools for virtual classes and meetings."
    ]
  },
  {
    name: "Online Learning & E-Learning Integration",
    subHeading: "Enable Digital Classrooms",
    description: "An integrated e-learning module to support online education, video lectures, and interactive learning resources.",
    bp: [
      "Provide students access to online courses, recorded lectures, and digital study materials.",
      "Enable real-time virtual classes with live streaming and interactive tools.",
      "Track student progress in online courses with analytics and insights.",
      "Integrate with popular LMS platforms for a seamless learning experience."
    ]
  }
];
export const transportErpHeadings = {
  heading: "Comprehensive Transport ERP Solutions",
  subHeading: "A powerful ERP system designed to streamline transport and logistics operations, enhance productivity, and optimise resource management for businesses of all sizes."
}

export const transportErpListDetails = [
  {
    name: "Fleet Management",
    subHeading: "Optimise Fleet Operations for Maximum Efficiency",
    description: "The Fleet Management module helps businesses manage and track their fleet operations in real-time. It ensures proper vehicle utilisation, reduces maintenance costs, and improves overall operational efficiency.",
    bp: [
      "Track real-time vehicle locations, routes, and mileage to improve operational control.",
      "Automate maintenance schedules and receive alerts for servicing to minimise vehicle downtime.",
      "Monitor fuel consumption, driver behaviour, and vehicle health to optimise efficiency and reduce costs."
    ]
  },
  {
    name: "Dispatch & Route Planning",
    subHeading: "Enhance Delivery Efficiency with Intelligent Route Planning",
    description: "The Dispatch & Route Planning module automates the allocation of vehicles and delivery scheduling. It optimises routes, reduces travel time, and ensures timely deliveries while minimising fuel costs.",
    bp: [
      "Automate dispatch processes by assigning the right vehicles to the right routes.",
      "Optimise delivery routes with AI-based route planning to reduce fuel consumption and costs.",
      "Monitor real-time traffic conditions and adjust routes dynamically to avoid delays."
    ]
  },
  {
    name: "Inventory & Warehouse Management",
    subHeading: "Streamline Warehouse & Supply Chain Operations",
    description: "This module helps businesses manage inventory levels, warehouse operations, and supply chain logistics efficiently within a single platform. It reduces inventory shortages and improves storage management.",
    bp: [
      "Track stock levels in real-time and receive alerts for low inventory.",
      "Optimise warehouse layout to enhance storage capacity and improve retrieval times.",
      "Automate order fulfilment, stock replenishment, and shipment tracking for seamless operations."
    ]
  },
  {
    name: "Driver & Employee Management",
    subHeading: "Ensure Workforce Productivity and Compliance",
    description: "The Driver & Employee Management module helps businesses manage driver schedules, track employee performance, and ensure regulatory compliance for transport staff.",
    bp: [
      "Automate driver scheduling and shift allocation to prevent conflicts and overworking.",
      "Ensure compliance with transportation regulations by maintaining accurate employee records.",
      "Monitor driver performance through detailed analytics on driving habits, punctuality, and adherence to routes."
    ]
  },
  {
    name: "Billing & Invoicing",
    subHeading: "Automate Financial Transactions with Precision",
    description: "This module simplifies financial operations by automating invoicing, payment tracking, and financial reporting, ensuring seamless transactions between clients, suppliers, and stakeholders.",
    bp: [
      "Generate invoices automatically based on completed trips, deliveries, and service agreements.",
      "Monitor payment statuses and send automated reminders to clients for overdue payments.",
      "Integrate with accounting software for accurate financial reporting and auditing."
    ]
  },
  {
    name: "Customer Portal",
    subHeading: "Enhance Customer Experience with a Self-Service Portal",
    description: "The Customer Portal provides clients with real-time access to their shipments, order placements, and notifications. It enhances transparency and communication between businesses and their customers.",
    bp: [
      "Enable customers to track their shipments in real-time with live updates.",
      "Provide automated notifications for order confirmations, dispatches, and deliveries.",
      "Allow customers to book transport services, view invoices, and raise support requests easily."
    ]
  },
  {
    name: "Analytics & Reporting",
    subHeading: "Gain Business Insights with Advanced Analytics",
    description: "This module provides detailed reports and analytics on key business operations, helping transport businesses make data-driven decisions for improved performance and cost savings.",
    bp: [
      "Track key performance indicators (KPIs) such as fleet efficiency, revenue, and cost analysis.",
      "Generate detailed reports on vehicle utilisation, trip performance, and profit margins.",
      "Use AI-driven predictive analytics to forecast future demand and optimise resource allocation."
    ]
  },
  {
    name: "Compliance & Safety Management",
    subHeading: "Ensure Regulatory Compliance & Safety Standards",
    description: "This module helps businesses comply with transport regulations, monitor driver safety, and maintain all necessary compliance records in a centralised system.",
    bp: [
      "Automate compliance reporting and document management to meet industry standards.",
      "Monitor driver logs, safety inspections, and accident reports to ensure regulatory adherence.",
      "Receive real-time alerts for policy violations and safety risks, ensuring proactive action."
    ]
  },
  {
    name: "GPS Tracking & Telematics",
    subHeading: "Improve Vehicle Monitoring and Security",
    description: "The GPS Tracking & Telematics module provides real-time location tracking, enhances vehicle security, and helps optimise operational efficiency through intelligent telematics solutions.",
    bp: [
      "Monitor vehicle locations, speed, and route history in real-time for better tracking and security.",
      "Receive geofencing alerts if a vehicle moves out of a predefined area.",
      "Use predictive maintenance alerts based on telematics data to prevent unexpected breakdowns."
    ]
  }
];

export const PageHeadings = {
  home: {
    title: "Welcome to Our Platform",
    title2: "Your One-Stop Solution for CRM & ERP Needs"
  },
  odoo: {
    title: "Odoo ERP Solutions Tailored for You",
    title2: "Streamline Your Business Operations with Odoo"
  },
  salesforce: {
    title: "Boost Sales & Customer Engagement with Salesforce",
    title2: "Get the Best CRM Solution for Your Business Growth"
  },
 
  trial: {
    title: "Start Your Free Trial Today!",
    title2: "Experience Our Services Risk-Free for a Limited Time"
  },
  contact: {
    title: "Worried About CRM, ERP Implementation Costs?",
    title2: "Fill out the form below, and we’ll reach out shortly."
  },
  schoolErp: {
    title: "Manage Your School Efficiently with Our ERP",
    title2: "Simplify Administration and Student Management"
  },
  transportErp: {
    title: "Optimize Your Transport Operations",
    title2: "Smart ERP Solutions for Fleet and Logistics Management"
  }
};






export const products = [
    {
      title: "SalesForce Sales Cloud",
      link: "https://www.salesforce.com/in/sales/",
      thumbnail:
        "../../../images/hero/SalesCloud.png",
    },
    {
      title: "Salesforce CPQ",
      link: "https://www.salesforce.com/sales/cpq/",
      thumbnail:
      "../../../images/hero/SalesCPQ.png",
    },
    {
      title: "Odoo Accounts",
      link: "https://www.odoo.com/app/accounting",
      thumbnail:
        "../../../images/hero/OdooAccounts.png",
    },
    {
      title: "Odoo HRMS",
      link: "https://www.odoo.com/app/employees",
      thumbnail: "../../../images/hero/OdooHRMS.png",
    },  
    {
      title: "Odoo Website",
      link: "https://www.odoo.com/app/website",
      thumbnail: "../../../images/hero/OdooWebsite.png",
    }, 
    {
      title: "SalesForce Einstein AI",
      link: "https://www.salesforce.com/in/artificial-intelligence/",    
      thumbnail:"../../../images/hero/SalesForceEinsteinAI.png",      
    },
    {
      title: "Odoo POS",
      link: "https://www.odoo.com/app/point-of-sale-shop",
      thumbnail:"../../../images/hero/OdooPOS.png",
    }, 
    {
      title: "SalesForce Financial Service Cloud",
      link: "https://www.salesforce.com/financial-services/cloud/",    
      thumbnail:"../../../images/hero/SFFinancialServiceCloud.png",
    },
    {
      title: "Odoo Marketing Automation",
      link: "https://www.odoo.com/app/marketing-automation",
      thumbnail:"../../../images/hero/OdooMarketingAutomation.png",
    },
    {
      title: "SalesForce Experience Cloud",
      link: "https://www.salesforce.com/in/products/experience-cloud/overview/",    
      thumbnail:"../../../images/hero/SFExperienceCloud.png",
    },
    {
      title: "Odoo Website",
      link: "https://www.odoo.com/app/website",
      thumbnail: "../../../images/hero/OdooWebsite.png",
    }, 
    {
      title: "SalesForce Marketing Cloud",
      link: "https://www.salesforce.com/in/marketing/",
      thumbnail: "../../../images/hero/SFMarketingCloud.png",
    },
    {
      title: "SalesForce Analytics",
      link: "https://www.salesforce.com/in/analytics/",
      thumbnail: "../../../images/hero/SFTableau.png",
    },
    {
      title: "Odoo Manufacturing CRM",
      link: "https://www.odoo.com/app/manufacturing",
      thumbnail: "../../../images/hero/OdooManufacturingCrm.png",
    },
  ]