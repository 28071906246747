import { useState } from "react";

const TrialPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await fetch("/api/trial", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage("Your trial request has been submitted successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        setSuccessMessage("Your trial request has been submitted successfully!");
        //setErrorMessage("Failed to submit your request. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className='light-background-gradient-color'>
    <div className="container mx-auto p-24">
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
    <h1 className="md:text-7xl text-3xl lg:text-6xl font-bold text-center relative z-20">
        Worried About <br /> CRM, ERP Implementation Costs?
      </h1>      
      <p className="max-w-4xl text-base md:text-xl p-4" style={{ maxWidth: "500px", margin: "auto" }}>
        Fill out the form below, and we’ll reach out shortly.
      </p>
      <form onSubmit={handleSubmit} style={{ maxWidth: "500px", margin: "auto" }}>
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            style={{
              display: "block",
              width: "100%",
              padding: "8px",
              margin: "5px 0",
            }}
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={{
              display: "block",
              width: "100%",
              padding: "8px",
              margin: "5px 0",
            }}
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            style={{
              display: "block",
              width: "100%",
              padding: "8px",
              margin: "5px 0",
            }}
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="message">Message (Optional)</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            style={{
              display: "block",
              width: "100%",
              padding: "8px",
              margin: "5px 0",
            }}
          />
        </div>

        <button
          type="submit"
          style={{                        
            padding: "10px 20px",
            border: "none",
            cursor: "pointer",
          }}
          className="btn btn-primary mt-8 text-white"
        >
          Book My Free Trial
        </button>
      </form>
        <div className="mx-auto">
        {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
    </div>
    </div>
    </div>
  );
};

export default TrialPage;
