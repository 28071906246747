import React from "react";
import { SparklesCore } from "./sparklesCore";

export function SparklesHero() {
  return (
    (<div
      className="h-[100vh] relative w-full bg-black flex flex-col items-center justify-center overflow-hidden rounded-md">
      <div className="w-full absolute inset-0 h-screen">
        <SparklesCore
          id="tsparticlesfullpage"
          background="transparent"
          minSize={0.6}
          maxSize={1.4}
          particleDensity={100}
          className="w-full h-full"
          particleColor="#FFFFFF" />
      </div>
      {/* <h1
        className="md:text-7xl text-3xl lg:text-6xl font-bold text-center text-white relative z-20">
        Build great products
      </h1> */}
      <h1 className="md:text-7xl text-3xl lg:text-6xl font-bold text-center text-white relative z-20">
        Worried About <br /> CRM, ERP Implementation Costs?
      </h1>
      <p className="max-w-4xl text-base md:text-xl mt-8 text-white ">
        We are a team of experienced passionate developers working in the ERP/CRM industry since 12 years. Let us onboard you business processes onto Salesforce/Odoo.        
      </p>
      <p className="max-w-4xl text-base md:text-xl mt-8 text-white ">And for the first two weeks, It is on us!</p>
      <a className="btn btn-primary mt-8 text-white z-10" href="/trial">Book Free 2 Weeks Trial</a>
    </div>)
  );
}
