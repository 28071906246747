import React from 'react'

function OdooCrm() {
  return (
    <>
     <section className="bg-[#10002b] text-white  py-40  px-4 md:px-16">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        {/* Left Section */}
        <h1 className="text-lg md:text-2xl font-bold text-center md:text-left">
          Odoo CRM Open Source Software Development
        </h1>

        {/* Breadcrumb Navigation */}
        <nav className="text-sm md:text-base mt-4 md:mt-0">
          <ul className="flex items-center gap-2 md:gap-4 text-gray-300">
            <li className="hover:text-white cursor-pointer">Home</li>
            <span className="text-gray-400">-</span>
            <li className="hover:text-white cursor-pointer">Apps</li>
            <span className="text-gray-400">-</span>
            <li className="text-white font-semibold">
              Odoo CRM Open Source Software Development
            </li>
          </ul>
        </nav>
      </div>

      
    </section>
     <section className="flex flex-col md:flex-row items-center justify-center bg-gray-50 px-6 md:px-16 mt-5 mb-5">
      {/* Left Content */}
      <div className="md:w-1/2 space-y-4">
        <h5 className="text-purple-600 text-sm font-semibold">
          // BUSINESS MANAGEMENT SUITE FOR EVERY BUSINESS GROWTH
        </h5>
        <h2 className="text-xl lg:text-4xl font-bold text-gray-900">
          Successful Implementation with Odoo CRM Software
        </h2>
        <h3 className="text-lg font-semibold text-gray-700">
          Track leads, convert them into customers and build loyal relationships
        </h3>
        <p className="text-gray-600 leading-relaxed">
          Odoo CRM implementation can make your business path grow exponentially. Oodu Implementers Private Limited functions with a team of skilled and certified developers who ensure that the entire Odoo CRM implementation process remains smooth.
        </p>
        <p className="text-gray-600 leading-relaxed">
          We strive to give the best solutions to our clients without disturbing their business operations. Our team sketches a step-by-step plan for a successful Odoo CRM implementation to execute the process in the most structured way.
        </p>
      </div>

      {/* Right Content - Video Box */}
      <div className="md:w-1/2 flex justify-center mt-6 md:mt-0 relative">
        <div className="relative w-full max-w-md rounded-lg overflow-hidden shadow-lg">
          <img
            src="https://www.odooimplementers.com/images/odoo-crm-software-implementation.webp"
            alt="Odoo CRM"
            className="w-full h-auto object-cover"
          />
          <button className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-3xl rounded-full w-14 h-14 mx-auto my-auto">
            ▶
          </button>
        </div>
      </div>

      
    </section>
        <section className="relative w-full bg-[#111133] py-16 px-6 md:px-16 text-white">
      {/* Decorative Wave Elements (Optional) */}
      <div className="absolute top-0 left-0 w-32 h-32 bg-gradient-to-r from-blue-500 to-purple-600 opacity-30 blur-2xl rounded-full"></div>
      <div className="absolute bottom-0 right-0 w-40 h-40 bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 blur-2xl rounded-full"></div>

      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between gap-10">
        {/* Left Side: Image */}
        <div className="md:w-1/2 flex justify-center">
          <img
            src="https://www.odooimplementers.com/images/menu/odoo-crm-software-analysis.webp"
            alt="Odoo CRM Dashboard"
            className="max-w-lg w-full rounded-lg shadow-lg"
          />
        </div>

        {/* Right Side: Text Content */}
        <div className="md:w-1/2 space-y-4">
          <h2 className="text-2xl lg:text-4xl font-bold">
            Odoo CRM Software to Manage Leads and Real-time Messages
          </h2>
          <p className="text-gray-300 leading-relaxed">
            Odoo CRM offers a technology-driven approach to enhance customer experience and cater to their needs effortlessly. With real-time messaging and effective online campaigns, it helps grab the attention of leads and build reliable relationships with customers.
          </p>
          <h3 className="text-xl font-semibold">
            Redefine Scalability and Flexibility with Odoo CRM
          </h3>
          <p className="text-gray-300 leading-relaxed">
            A cutting-edge, user-friendly Odoo CRM portal to enhance business performance and productivity. Odoo CRM solution fits the needs of enterprise solutions and transforms your business operations.
          </p>
        </div>
      </div>
    </section>
     <section className="w-full bg-gray-100 py-16 px-6 md:px-16">
      {/* Benefits Section */}
      <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-8">
        {/* Card 1 */}
        <div className="bg-white p-6 shadow-lg rounded-lg flex flex-col items-center text-center">
          {/* Icon Placeholder (Replace with SVG or Icon Component) */}
          <div className="text-4xl">📊</div>
          <h3 className="text-xl font-semibold mt-4">Get Detailed Predictions</h3>
          <p className="text-gray-600 mt-2">
            Odoo CRM sets up automated alerts and notifications so that you can stay on top of your sales and marketing activities.
          </p>
        </div>

        {/* Card 2 */}
        <div className="bg-white p-6 shadow-lg rounded-lg flex flex-col items-center text-center">
          {/* Icon Placeholder */}
          <div className="text-4xl">📈</div>
          <h3 className="text-xl font-semibold mt-4">Reap Innumerable Benefits</h3>
          <p className="text-gray-600 mt-2">
            Practical tools to implement various business functionalities. Affordable and well-curated Odoo CRM portal.
          </p>
        </div>
      </div>

    </section>
   
     <section className="w-full bg-gray-100 py-16 px-6 md:px-16">
      {/* Container */}
      <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-12">
        {/* Left Content */}
        <div>
          <h2 className="text-2xl font-bold text-gray-800">
            Why Oodu Implementers for Odoo CRM?
          </h2>
          <ul className="mt-4 space-y-2 text-gray-600 list-disc list-inside">
            <li>Renowned Partner for Odoo CRM Implementation.</li>
            <li>Perfectly designed customer-centric Odoo CRM Portal.</li>
            <li>An outstanding Odoo CRM implementation for your business.</li>
            <li>Blend of existing technologies with the latest developments.</li>
            <li>Client-friendly approach and process-driven methodology.</li>
            <li>Odoo CRM partner.</li>
            <li>Round-the-clock assistance.</li>
            <li>Top-notch user experience.</li>
          </ul>
        </div>

        {/* Right Image */}
        <div className="flex justify-center">
          <img
            src="https://www.odooimplementers.com/images/menu/odoo-crm-software-analysis.webp"

            alt="Odoo CRM Chat"
            className="max-w-md w-full rounded-lg shadow-lg"
          />
        </div>
      </div>

      {/* Second Section */}
      <div className="max-w-7xl mx-auto mt-16 grid md:grid-cols-2 gap-12 items-center">
        {/* Left Image */}
        <div className="flex justify-center">
          <img
            src="https://www.odooimplementers.com/images/menu/odoo-crm-software-analysis.webp"
            alt="Odoo CRM Sales"
            className="max-w-lg w-full rounded-lg shadow-lg"
          />
        </div>

        {/* Right Content */}
        <div>
          <h2 className="text-2xl font-bold text-gray-800">
            Redeeming Features of Odoo CRM
          </h2>
          <ul className="mt-4 space-y-2 text-gray-600 list-disc list-inside">
            <li>Numerable tools for a fast and effective business management.</li>
            <li>Insightful data to make smart business decisions.</li>
            <li>Real-time reports analyzing business performance.</li>
            <li>A custom dashboard to review your activities and next move.</li>
            <li>Real-time messaging to enhance collaboration with customers.</li>
            <li>Easily track business transactions in no time.</li>
          </ul>
        </div>
      </div>
    </section>
     <section>
       <div className="relative w-full h-[200px] md:h-[300px] lg:h-[400px] flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: "url('https://source.unsplash.com/1600x900/?team,office')" }}>
      
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Text Content */}
      <div className="relative text-center text-white px-4">
        <h2 className="text-lg md:text-2xl font-semibold">
          A Single Odoo CRM Software for all your needs.
        </h2>
        <p className="text-sm md:text-lg mt-1">We are What We Do</p>
        <button className="mt-4 bg-purple-600 hover:bg-purple-700 text-white py-2 px-6 rounded-lg">
          CONTACT US
        </button>
      </div>
    </div>
    </section>
    <section className="bg-gray-100 py-12 px-6 md:px-16">
    

      {/* Footer Links */}
      <div className="max-w-7xl mx-auto mt-12 grid md:grid-cols-4 gap-8 text-gray-700">
        {/* Company */}
        <div>
          <h3 className="font-bold">COMPANY</h3>
          <ul className="mt-2 space-y-1">
            <li>About Us</li>
            <li>Why Choose Us</li>
            <li>Our Quality Process</li>
            <li>Our Clients</li>
            <li>Careers</li>
          </ul>
        </div>

        {/* Services */}
        <div>
          <h3 className="font-bold">SERVICES</h3>
          <ul className="mt-2 space-y-1">
            <li>Consulting</li>
            <li>Configuration</li>
            <li>Customization</li>
            <li>Development</li>
            <li>Implementation</li>
          </ul>
        </div>

        {/* Apps */}
        <div>
          <h3 className="font-bold">APPS</h3>
          <ul className="mt-2 space-y-1">
            <li>CRM</li>
            <li>Invoicing</li>
            <li>Sales</li>
            <li>E-Commerce</li>
            <li>Point Of Sale</li>
          </ul>
        </div>

       
        {/* Menu */}
        <div>
          <h3 className="font-bold">MENU</h3>
          <ul className="mt-2 space-y-1">
            <li>Blog</li>
            <li>Photo Gallery</li>
            <li>Video Gallery</li>
            <li>Our Corporate PPT</li>
          </ul>
        </div>
      </div>

     
    </section>


    </>
  )
}

export default OdooCrm