import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CRMListItems from './CRMListItems';
import CRMDetails from './CRMDetails';

function CRMServices(props) {
  const [selected, setSelected] = useState(0);
  const { data, headings } = props;

  return (
    <div className="light-background-gradient-color">
      <div className="container mx-auto p-6 sm:p-24">
        <h1 className="text-2xl sm:text-4xl font-bold">{headings.heading}</h1>
        <p className="pt-2 sm:pt-3 text-sm sm:text-base text-[#4e5158]">{headings.subHeading}</p>
        
        {/* Flex column for mobile, row for larger screens */}
        <div className="flex flex-col sm:flex-row mt-4 sm:mt-8">
          <div className="w-full sm:w-1/2">
            <CRMListItems data={data} selectedIndex={selected} onClick={setSelected} />
          </div>
          <div className="w-full sm:w-1/2 mt-4 sm:mt-0 overflow-auto">
            <CRMDetails data={data} selectedIndex={selected} />
          </div>
        </div>
      </div>
    </div>
  );
} 

CRMServices.propTypes = {};

export default CRMServices;  