import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import ContactForm from '../Components/ContactForm';
import { PageHeadings } from '../Content/Content';

function ContactPage({heading}) {
  

  return (
   <>
    <Helmet>
    <title>Tabvik - contact</title>
    </Helmet> 
    <ContactForm headings={PageHeadings.contact}/> 

   </>
  
  );
}

export default ContactPage;