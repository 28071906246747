import { Helmet } from "react-helmet";
import ContactForm from "../Components/ContactForm";
import { PageHeadings } from "../Content/Content";

const TrialPage = () => {

  return (
    <>
      <Helmet>
        <title>Tabvik - Trial</title>
      </Helmet>
     <ContactForm  headings={PageHeadings.trial}/>
    </>
   
  );
};

export default TrialPage;

