import React from 'react'
import PropTypes from 'prop-types'
import CRMServices from '../Components/CRMServices';
import { odooImplementationHeadings, odooListDetails, PageHeadings } from '../Content/Content';
import { Helmet } from 'react-helmet';
import ContactForm from '../Components/ContactForm';


function OdooPage(props) {
  return (<div>
    <Helmet>
      <title>Tabvik - Odoo</title>
    </Helmet>    
    <CRMServices data= {odooListDetails} headings= {odooImplementationHeadings}></CRMServices>  
    <ContactForm headings={PageHeadings.odoo}/>  
  </div>)
}

OdooPage.propTypes = {}

export default OdooPage