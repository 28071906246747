import React from 'react'
import PropTypes from 'prop-types'
import CRMSetvices from '../Components/CRMServices';
import { PageHeadings, transportErpHeadings,transportErpListDetails } from '../Content/Content';
import ContactForm from '../Components/ContactForm';
import { Helmet } from 'react-helmet';

function TransportErp(props) {
  return (<div>
     <Helmet>
      <title>Tabvik - TransportErp</title>
      </Helmet> 
    <CRMSetvices data= {transportErpListDetails} headings={transportErpHeadings}></CRMSetvices> 
    <ContactForm headings={PageHeadings.transportErp}/>
  </div>)
}
TransportErp.propTypes = {}

export default TransportErp
