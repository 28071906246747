import React from 'react'
import PropTypes from 'prop-types'

function OurServices(props) {
  return (
    <div className="h-[100vh] mt-8 mx-auto text-center">Our SalesForce Services</div>

  )
}

OurServices.propTypes = {}

export default OurServices
