import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { eCommerceCRMHeadings, odooListDetails } from '../Content/Content'
import CRMListItems from './CRMListItems';
import CRMDetails from './CRMDetails';


function CRMServices(props) {
    
  const [selected, setSelected] = useState("0");
  const {data, headings} = props;
    
  return (
    <div className='light-background-gradient-color'>
    <div className="container mx-auto p-24">
        <div className="text-4xl font-bold">{headings.heading}</div>
        <div className="pt-3 light text-base text-[#4e5158]"> {headings.subHeading}</div>        
        <div className='flex flex-row'>
            <div className='basis-1/2'>
             <CRMListItems data={data} selectedIndex= {selected} onClick={setSelected}></CRMListItems>
            </div>
            <div className='basis-1/2'>
            <CRMDetails data={data} selectedIndex= {selected}></CRMDetails>
            </div>
        </div>

    </div>
    </div>
  )
}

CRMServices.propTypes = {}

export default CRMServices
