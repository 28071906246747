import React from 'react';
import PropTypes from 'prop-types';

function ListItem({ item, index, onClick, selectedIndex }) {
    return (
        <li 
            key={index} 
            onClick={() => onClick(index)} 
            className={`card bg-base-100 shadow-xl my-2 md:my-4 md:mr-4 
            hover:bg-[#191d26] hover:text-white 
            ${selectedIndex === index ? "bg-[#191d26] text-black" : ""} 
            cursor-pointer w-full`}
        >      
            <div className="card-body px-4">
                <h3 className="card-title truncate">{item.name || ""}</h3>
                <p className="text-sm text-black-600 overflow-hidden text-ellipsis">{item.subHeading}</p>
            </div>
        </li>
    );
}

ListItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    selectedIndex: PropTypes.number.isRequired,
};

function CRMListItems({ data, selectedIndex, onClick }) {
    return (
        <div className="flex flex-col h-[40rem] overflow-auto w-full px-2">
            {data.map((item, index) => (
                <ListItem 
                    key={index} 
                    index={index} 
                    item={item} 
                    onClick={onClick} 
                    selectedIndex={selectedIndex} 
                />
            ))}
        </div>    
    );
}

CRMListItems.propTypes = {
    data: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default CRMListItems;
