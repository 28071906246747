import React from 'react'
import PropTypes from 'prop-types'
import CRMSetvices from '../Components/CRMServices';
import { PageHeadings, schoolERPHeadings,schoolERPListDetails } from '../Content/Content';
import ContactForm from '../Components/ContactForm';
import { Helmet } from 'react-helmet';

function School_ERP(props) {
  const portals = [
  {
    title: "Admin Portal",
    description:
      "Run your institute—or group of institutes—from the palm of your hand. With all the administrative information on a single, readily accessible dashboard, you'll never have to waste time searching through tabs and files.",
    icon: "🧑",
  },
  {
    title: "Faculty Portal",
    description:
      "Make it easier for faculty to collect student information and schedules, and mark sheets at a glance. Organize lesson plans, connect with colleagues, students, and management, and view courses right from their portal.",
    icon: "🎓",
  },
  {
    title: "Student Portal",
    description:
      "Put an end to crowding around the bulletin board for academic and extracurricular updates. Students can now instantly download study materials and help improve the learning experience for others by submitting course content.",
    icon: "📚",
  },
  {
    title: "Parent Portal",
    description:
      "Parents can learn all about what's happening at the institute—and their child's performance—just by logging into their dashboard. They can also make payments and get receipts through the portal.",
    icon: "👨‍👩‍👧",
  },
];
  return (
  <>
      <Helmet>
      <title>Tabvik - SchoolErp</title>
      </Helmet> 
  
    <div>
      <section className="bg-gray-50 m-5 py-12 px-6 md:px-16 lg:px-24 flex flex-col md:flex-row items-center justify-between">
      <div className="max-w-2xl">
        <h1 className="text-3xl md:text-4xl font-bold text-gray-900">
          Reduce Costs by <span className="text-yellow-500">40%</span> and Transform Your <br />
          <span className="text-yellow-600">School in 3 Months</span>
        </h1>
        <p className="mt-4 text-gray-700">
          Running an institution shouldn’t feel like drowning in admin tasks. <span className="font-bold">Educians streamlines operations,</span>
          automates administration, and puts you back in control. <span className="font-bold">Trusted by 400+ schools</span>, we let you focus on what
          truly matters—empowering education.
        </p>
        <button className="mt-4 btn btn-primary text-white px-6 py-2 rounded-lg hover:bg-gray-800 transition">
          <a href="/contact">Book a free demo</a> 
        </button>
       
      </div>
      
      
        
       
      
      
      
    </section>
    <div className='Container w-auto'>
          <img src="https://schools.educian.com/admin_dash.webp" width="100%"/>
        </div>
    <CRMSetvices data= {schoolERPListDetails} headings={schoolERPHeadings}></CRMSetvices>
       <section className="bg-white py-12 px-6 mx-5 md:px-16 lg:px-24">
      <div className="grid md:grid-cols-2 gap-12">
        {portals.map((portal, index) => (
          <div key={index} className="flex items-start space-x-4">
            <div className="text-3xl bg-gray-200 p-3 rounded-full">{portal.icon}</div>
            <div>
              <h2 className="text-xl font-bold text-gray-900">{portal.title}</h2>
              <p className="text-gray-700 mt-2">{portal.description}</p>
            </div>
          </div>
        ))}
      </div>
      
    </section> 
    <ContactForm headings={PageHeadings.schoolErp}/>
     
  </div>
  </>
  
  )
}

School_ERP.propTypes = {}

export default School_ERP 
