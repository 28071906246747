import React, { useState } from 'react';

function ContactForm(props) {
     const {  headings } = props;
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
        lookingFor: "",
      });
   
      const [successMessage, setSuccessMessage] = useState("");
      const [errorMessage, setErrorMessage] = useState("");
   
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
      };
   
      const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage("");
        setErrorMessage("");
   
        try {
          const response = await fetch("https://api.tabvik.com/lead", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });
   
          if (response.ok) {
            setSuccessMessage("Your trial request has been submitted successfully!");
            setFormData({
              name: "",
              email: "",
              phone: "",
              message: "",
              lookingFor: "",
            });
          } else {
            setErrorMessage("An error occurred. Please try again later.");
          }
        } catch (error) {
          setErrorMessage("An error occurred. Please try again later.");
        }
      };
  return (
    <>
    <div className="light-background-gradient-color">
    <div className="container mx-auto px-4 py-12 sm:py-16 md:py-24">
      <div className="text-center px-4">
        <h1 className="text-3xl md:text-6xl font-bold leading-tight">
        {headings.title}
        </h1>
        <p className="text-base md:text-xl mt-4 max-w-xl mx-auto">
        {headings.title2}
        </p>
      </div>

      <form 
        onSubmit={handleSubmit} 
        className="mt-6 mx-auto w-full max-w-lg px-4 sm:px-6"
      >
        <div className="mb-4">
          <label htmlFor="name" className="block font-medium">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block font-medium">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block font-medium">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="lookingFor" className="block font-medium">Looking For</label>
          <select
            id="lookingFor"
            name="lookingFor"
            value={formData.lookingFor}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded-md"
          >
            <option value="">Select an option</option>
            <option value="School ERP">School ERP</option>
            <option value="Transport ERP">Transport ERP</option>
            <option value="HRMS">HRMS</option>
            <option value="Recruitment">Recruitment</option>
            <option value="CRM">CRM</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="message" className="block font-medium">Message (Optional)</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary w-full sm:w-auto px-6 py-3 text-white rounded-md"
        >
          Book My Free Trial
        </button>
      </form>

      <div className="text-center mt-4">
        {successMessage && <p className="text-green-600">{successMessage}</p>}
        {errorMessage && <p className="text-red-600">{errorMessage}</p>}
      </div>
    </div>
  </div>
  </>
  )
}

export default ContactForm