import React, { useEffect } from 'react';

const ThankYouPage = () => {
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-16972438628/XXXXXXXXXXX', // Replace with your actual label
      });
    }
  }, []);

  return (
    <div className="light-background-gradient-color min-h-screen flex items-center justify-center px-4">
      <div className="container mx-auto p-6 sm:p-24 text-center">
        <h1 className="text-3xl sm:text-5xl font-bold text-primary">
          Thank You!
        </h1>
        <p className="text-base-content text-sm sm:text-base mt-4 sm:mt-6">
          We’ve received your request. Our team will contact you soon.
        </p>
        <a
          href="/"
          className="btn btn-primary w-full sm:w-auto px-6 py-3 mt-6 sm:mt-10"
        >
          Back to Home
        </a>
      </div>
    </div>
  );
};

export default ThankYouPage;
