import React from 'react'
import PropTypes from 'prop-types'
import CRMServices from '../Components/CRMServices';
import { odooImplementationHeadings, odooListDetails } from '../Content/Content';

function OdooPage(props) {
  return (<div>    
    <CRMServices data= {odooListDetails} headings= {odooImplementationHeadings}></CRMServices>     
  </div>)
}

OdooPage.propTypes = {}

export default OdooPage