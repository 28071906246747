import React from "react";

function Footer() {
  return (
    <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-300 p-10">
      <footer className="footer flex flex-col md:flex-row md:justify-around text-center md:text-left space-y-6 md:space-y-0">
        
        {/* Services Section */}
        <nav>
          <h6 className="footer-title text-xl font-semibold text-white mb-4">Services</h6>
          <a className="link link-hover block text-gray-400 hover:text-teal-400 transition duration-300">
            Odoo
          </a>
          <a className="link link-hover block text-gray-400 hover:text-teal-400 transition duration-300">
            SalesForce
          </a>
          <a className="link link-hover block text-gray-400 hover:text-teal-400 transition duration-300">
            Transport ERP
          </a>
          <a className="link link-hover block text-gray-400 hover:text-teal-400 transition duration-300">
            School ERP
          </a>
        </nav>

        {/* Company Section */}
        <nav>
          <h6 className="footer-title text-xl font-semibold text-white mb-4">Company</h6>
          <a href="/about"    className="link link-hover block text-gray-400 hover:text-teal-400 transition duration-300">
            About us
          </a>
          <a href="/contact" className="link link-hover block text-gray-400 hover:text-teal-400 transition duration-300">
            Contact
          </a>
        </nav>

        {/* Legal Section */}
        <nav>
          <h6 className="footer-title text-xl font-semibold text-white mb-4">Legal</h6>
          <a href="./terms" className="link link-hover block text-gray-400 hover:text-teal-400 transition duration-300">
            Terms of use
          </a>
          <a href="./privacy-policy" className="link link-hover block text-gray-400 hover:text-teal-400 transition duration-300">
            Privacy policy
          </a>
          <a className="link link-hover block text-gray-400 hover:text-teal-400 transition duration-300">
            Cookie policy
          </a>
        </nav>
      </footer>

      {/* Bottom Section */}
      <div className="text-center mt-8 text-gray-400 text-sm border-t border-gray-700 pt-4">
        © {new Date().getFullYear()} TABVIK. All rights reserved.
      </div>
    </div>
  );
}

export default Footer;
