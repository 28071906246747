import React from 'react'
import PropTypes from 'prop-types'
import { HeroParallax } from '../Components/ui/hero-parallax';
import { SparklesHero } from '../Components/ui/sparklesHero';
import OurServices from '../Components/OurServices';
import CRMServices from '../Components/CRMServices';
import { odooImplementationHeadings, odooListDetails } from '../Content/Content';

function HomePage(props) {
  return (<div>
    <SparklesHero></SparklesHero>
    <HeroParallax></HeroParallax>
    {/* <GridBackground></GridBackground> */}
    {/* <OurServices></OurServices> */}
    <CRMServices data= {odooListDetails} headings= {odooImplementationHeadings}></CRMServices>     
  </div>)
}

HomePage.propTypes = {}

export default HomePage