import logo from './logo.svg';
import './App.css';
import CRMSetvices from './Components/CRMServices';
import NavBar from './Components/NavBar';
import Footer from './Components/Footer';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OdooPage from './Pages/OdooPage';
import SalesforcePage from './Pages/SalesforcePage';
import HomePage from './Pages/HomePage';
import TermsOfUse from './Pages/TermsOfUse';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TrialPage from './Pages/TrialPage';


function App() {
  return (
    <Router>    
      <NavBar></NavBar>              
      <Routes>        
        <Route path="/odoo" element={<OdooPage />} />
        <Route path="/salesforce" element={<SalesforcePage />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />   
        <Route path="/trial" element={<TrialPage />} />        
        <Route path="/" element={<HomePage />} />
        {/* Define other routes here */}
      </Routes>
      <Footer></Footer>
    </Router>
  );
}

export default App;
